@font-face {
	font-display: swap;
    font-family: 'TitilliumWeb';
    src: url("/fonts/TitilliumWeb-ExtraLight.woff") format('woff'),
         url("/fonts/TitilliumWeb-ExtraLight.ttf") format('truetype');
    font-weight: 300;
}
@font-face {
	font-display: swap;
    font-family: 'TitilliumWeb';
    src: url("/fonts/TitilliumWeb-Light.woff") format('woff'),
         url("/fonts/TitilliumWeb-Light.ttf") format('truetype');
    font-weight: 400;
}
@font-face {
	font-display: swap;
    font-family: 'TitilliumWeb';
    src: url("/fonts/TitilliumWeb-Regular.woff") format('woff');
    src: url("/fonts/TitilliumWeb-Regular.ttf") format('truetype');
    font-weight: 500;
}
@font-face {
	font-display: swap;
    font-family: 'TitilliumWeb';
    src: url("/fonts/TitilliumWeb-SemiBold.woff") format('woff');
    src: url("/fonts/TitilliumWeb-SemiBold.ttf") format('truetype');
    font-weight: 600;
}
@font-face {
	font-display: swap;
    font-family: 'TitilliumWeb';
    src: url("/fonts/TitilliumWeb-Bold.woff") format('woff');
    src: url("/fonts/TitilliumWeb-Bold.ttf") format('truetype');
    font-weight: 700;
}

@font-face {
	font-display: swap;
	font-family: 'DINCond';
	src: url("/fonts/DINCond-Medium.woff2") format("woff2"),
		url("/fonts/DINCond-Medium.woff") format("woff"),
		url("/fonts/DINCond-Medium.ttf") format("truetype"),
		url("/fonts/DINCond-Medium.otf") format("opentype");
}

@font-face {
	font-display: swap;
	font-family: 'Baskerville URW';
	src: url("/fonts/baskerville/BaskervilleURWExtNar-RegObl.woff2") format('woff2'),
		 url("/fonts/baskerville/BaskervilleURWExtNar-RegObl.woff") format('woff'),
		 url("/fonts/baskerville/BaskervilleURWExtNar-RegObl.ttf") format('truetype');
}
@font-face {
	font-display: swap;
	font-family: 'Baskerville URW';
	src: url("/fonts/baskerville/BaskervilleURWWid-Reg.woff2") format('woff2'),
		 url("/fonts/baskerville/BaskervilleURWWid-Reg.woff") format('woff'),
		 url("/fonts/baskerville/BaskervilleURWWid-Reg.ttf") format('truetype');
}
@font-face {
	font-display: swap;
	font-family: 'Baskerville URW';
	src: url("/fonts/baskerville/BaskervilleURW-Bol.woff2") format('woff2'),
		 url("/fonts/baskerville/BaskervilleURW-Bol.woff") format('woff'),
	 	 url("/fonts/baskerville/BaskervilleURW-Bol.ttf") format('truetype');
	font-weight: 600;
}

@font-face {
	font-display: swap;
	font-family: 'Avenir Next';
	src: url("/fonts/avenir-next/AvenirNext-UltraLight.woff2") format('woff2'),
		 url("/fonts/avenir-next/AvenirNext-UltraLight.woff") format('woff'),
		 url("/fonts/avenir-next/AvenirNext-UltraLight.ttf") format('truetype');
	font-weight: 300;
}
@font-face {
	font-display: swap;
	font-family: 'Avenir Next';
	src: url("/fonts/avenir-next/AvenirNext-Regular.woff2") format('woff2'),
		 url("/fonts/avenir-next/AvenirNext-Regular.woff") format('woff'),
		 url("/fonts/avenir-next/AvenirNext-Regular.ttf") format('truetype');
	font-weight: 400;
}
@font-face {
	font-display: swap;
	font-family: 'Avenir Next';
	src: url("/fonts/avenir-next/AvenirNext-Medium.woff2") format('woff2'),
		 url("/fonts/avenir-next/AvenirNext-Medium.woff") format('woff'),
		 url("/fonts/avenir-next/AvenirNext-Medium.ttf") format('truetype');
	font-weight: 500;
}
@font-face {
	font-display: swap;
	font-family: 'Avenir Next';
	src: url("/fonts/avenir-next/AvenirNext-DemiBold.woff2") format('woff2'),
		 url("/fonts/avenir-next/AvenirNext-DemiBold.woff") format('woff'),
		 url("/fonts/avenir-next/AvenirNext-DemiBold.ttf") format('truetype');
	font-weight: 700;
}

* {
	box-sizing: border-box;
	background-size: cover;
	background-position: center;
	object-fit: cover;
	object-position: center;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	background: $color-white;
}

a {
	text-decoration: none;
	transition: opacity .2s ease;

	&:hover {
		opacity: .7;
		cursor: pointer;
	}
	&:active {
		opacity: .5;
	}
}

button, 
input {
	font-family: $typeface-store;
}

button {
	outline: none;
	box-shadow: none;
}

.container-width {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	max-width: 800px;
	padding-right: 20px;
	padding-left: 20px;
}

.margin-bottom {
	margin-bottom: 40px;
	@media (min-width: $tablet-break) {
		margin-bottom: 60px;
	}
	@media (min-width: $desktop-s-break) {
		margin-bottom: 80px;
	}
	@media (min-width: $desktop-l-break) {
		margin-bottom: 100px;
	}
	@media (min-width: $desktop-xl-break) {
		margin-bottom: 120px;
	}
}

.padding-bottom {
    padding-bottom: 80px;
    
	@media (max-width: $mobile-break) {
		padding-bottom: 40px;
	}
}

.service-content-block {
	width: 1480px;
	margin: auto;
	margin-bottom: 120px;
	padding: 0;
	border-style: none;

	@media screen and (max-width: $desktop-l-break) {
			width: 1000px;
			margin-bottom: 100px;
	}
	@media screen and (max-width: $desktop-s-break) {
			width: 740px;
			margin-bottom: 80px;
	}
	@media screen and (max-width: $tablet-break) {
			width: 100%;
			padding: 0 20px;
			margin-bottom: 40px;
	}
}

.mid-width {
	margin-right: auto;
	margin-left: auto;
	width: calc(100% - 40px);
	
	@media (min-width: $tablet-break) {
			margin-right: auto;
			margin-left: auto;
			width: 85%;
			max-width: 1640px;
	}
	// @media (min-width: $desktop-s-break) {
	//     max-width: rem(940px);
	// }
	// @media (min-width: $desktop-l-break) {
	//     max-width: rem(1160px);
	// }
	// @media (min-width: $desktop-xl-break) {
	//     max-width: rem(1640px);
	// }
}

.content-background {
	background-color: $color-white;
	width: 100%;
	height: 100%;
}

.content {
	&_row,
	&_col,
	&_center {
		display: flex;
	}

	&_row {
		flex-direction: row;

		// @media(max-width: $mobile-break) {
		// 	flex-direction: column;
		// }
	}

	&_col {
		flex-direction: column;
	}

	&_center {
		align-items: center;
		justify-content: center;
	}
}

.subtitle {
	margin-top: 0;
	letter-spacing: -2px;
	font-family: $typeface-store;
	
	font-size: 20px;
	@media (min-width: $desktop-s-break) {
		font-size: 24px;
	}
	@media (min-width: $desktop-l-break) {
		font-size: 30px;
	}
	@media (min-width: $desktop-xl-break) {
		font-size: 40px;
	}
	&_gold {
		color: $color-royal-gold;
	}
}

.section__title {
	font-size: 40px;
	text-align: center;

	@media(max-width: $desktop-l-break) {
		font-size: 32px;
	}

	@media(max-width: $tablet-break) {
		font-size: 24px;
	}
}

.section__subtitle {
	font-size: 20px;

	@media (max-width: $desktop-s-break) {
		font-size: 16px;
	}
}

.input {
	border-radius: 0;
	padding: 8px;

	&_grey {
		border: 1px solid $color-border;
	}
}

.button {
	cursor: pointer;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}

	&_blue,
	&_grey,
	&_grey-blue,
	&_grey-blue-fade,
	&_gold,
	&_gold-fade,
	&_white-shadow,
	&_green {
		width: max-content;
		padding: .5rem 1rem;
		text-align: center;
		box-shadow: none;
		transition: all .2s ease;
		text-decoration: none;

		&:hover {
			opacity: 0.7;
			transition: all .2s ease;
			text-decoration: none;
		}

		&:active,
		&:focus {
			transition: all .2s ease;
			user-select: none;
			text-decoration: none;
		}
	}

	&_blue,
	&_grey,
	&_grey-blue,
	&_gold,
	&_green {
		color: white;

		&:hover,
		&:active,
		&:focus,
		&:visited {
			color: white;
		}
	}

	&_grey-blue-fade,
	&_gold-fade {
		background: white;
	}

	&_grey-blue,
	&_grey-blue-fade {
		border: 1px solid $color-grey-blue;
	}

	&_gold,
	&_gold-fade {
		border: 1px solid $color-royal-gold;
	}

	&_blue {
		background: $color-blue;
		border: 1px solid $color-blue;
	}

	&_grey {
		background: $color-dark-faded;
		border: 1px solid $color-dark-faded;
	}

	&_grey-blue {
		background: $color-grey-blue;
		border: 1px solid $color-grey-blue;
	}

	&_gold {
		background: $color-royal-gold;
		border: 1px solid $color-royal-gold;
	}

	&_grey-blue-fade {
		color: $color-grey-blue;
	}

	&_gold-fade {
		color: $color-royal-gold;
	}

	&_white-shadow {
		background: white;
		color: $color-black;
		box-shadow: 0 0 10px rgba($color-black, 0.25);
		border-radius: 4px;
	}

	&_green {
		background: $color-positive;
		border: 1px solid $color-positive;
	}
}

.text {
	&_bold {
		font-weight: 600;
	}

	&_mid {
		font-weight: 500;
	}

	&_reg {
		font-weight: 400;
	}

	&_thin {
		font-weight: 300;
	}

	&_heading-baskerville {
		font-family: $typeface-baskerville;
		font-weight: 600;
	}

	&_heading-underline {
		font-weight: 300;
		text-transform: uppercase;
		border-bottom: 2px solid $color-royal-gold;
	}

	&_center {
		text-align: center;
	}

	&_blue,
	&_blue:hover {
		color: $color-blue;
	}

	&_grey-blue,
	&_grey-blue:hover {
		color: $color-grey-blue;
	}

	&_gold,
	&_gold:hover {
		color: $color-royal-gold;
	}

	&_dark,
	&_dark:hover {
		color: $color-dark;
	}

	&_grey,
	&_grey:hover {
		color: $color-dark-faded;
	}

	&_white,
	&_white:hover {
		color: $color-white;
	}

	&_underline,
	&_underline:hover {
		text-decoration: underline;
	}

	&_uppercase {
		text-transform: uppercase;
	}

	&_italic {
		font-style: italic;
	}
}

.bg {
	&_black {
		background: $color-black;
	}

	&_white {
		background: $color-white;
	}

	&_grey-blue {
		background: $color-grey-blue;
	}

	&_grey {
		background: $color-grey-blue;
		color: $color-white;
	}

	&_pale-grey {
		background: $color-background;
	}

	&_white-transparent {
		background: rgba($color-white, 0.65);
	}

	&_dark {
		background: $color-dark;
	}

	&_dark-faded {
		background: $color-dark-faded;
	}

	&_black {
		background: $color-black;
	}

	&_gold {
		background: $color-royal-gold;
	}

	&_popup {
		background: rgba($color-dark, 0.65);
	}
}

.background_gradient {
	background: $color-royal-gold;
	background: linear-gradient(30deg, $color-royal-gold 0%, #FBDAB6 70%);
}

.alert {
	&_fixed-top {
		position: fixed;
		top: 8px;
		width: 100%;

		@media(max-width: $mobile-break) {
			top: 0;
		}

		&.disabled {
			opacity: 0;
			z-index: -1;
			transition: all .2s ease;
		}
	}

	&_black {
		display: flex;
		justify-content: center;
		width: max-content;
		margin: auto;
		padding: 0.5rem 1.25rem;
		background: rgba(0, 0, 0, 0.75);
		border-radius: 4px;
		color: $color-white;
		font-size: 16px;

		@media(max-width: $tablet-break) {
			width: auto;
			margin: 20px;
			font-size: 14px;
		}

		* {
			color: $color-white;
		}
	}

	&__close {
		margin-left: 8px;
	}
}