$color-blue: #0d6db1;
$color-white: #fff;
$color-background: #f5f5f5;
$color-border: #e0e0e0;
$color-dark-faded: #9e9e9e;
$gray-1:#f9fafa;
$color-dark: #424242;
$color-black: #000000;
$color-positive: #0db13f;
$color-alert: #e00000;
$color-dark-transparent: rgba(58, 64, 75, 0.8);

// new
$color-light-orange: #FBDAB6;

// Store color
$color-grey-blue: #3a404b;
$color-royal-gold: #B6915D;

// $color-text: #424242;
// $color-text-faded: #9e9e9e;
// $color-text-accent: #FC8200;
// $color-text-brand: #0d6db1;

$typeface-main: 'TitilliumWeb', 'Helvetica', Arial, sans-serif;
$typeface-lato: 'Lato', 'Helvetica', Arial, sans-serif;
$typeface-dincond: 'DINCond', 'Helvetica', Arial, sans-serif;
$typeface-store: 'Avenir Next', 'Helvetica', Arial, sans-serif;
$typeface-baskerville: 'Baskerville URW', 'Georgia', 'Times New Roman', serif;
$typeface-baskerville-exw: 'Baskerville-exw-URW', 'Georgia', 'Times New Roman', serif;

$min-mobile-break: 400px;
$mobile-break: 767px;
$tablet-break: 1023px;
$desktop-s-break: 1279px;
$desktop-l-break: 1439px;
$desktop-xl-break: 1899px;

$nav-height-mobile: rem(60px);
$nav-height-tablet: rem(80px);
$nav-height-desktop-s: rem(120px);

