$blue-600: #000f29;
$blue-500: #002566;
$sky-600: #001f29;
$sky-500: #005c7a;
$sky-400: #0ac2ff;
$sky-300: #adebff;
$blue-200: #c2d8ff;
$sky-200: #d6f5ff;
$blue-400-brand: #2979ff;
$blue-100: #ebf2ff;
$sky-100: #ebfaff;
$blue-300: #85b1ff;
$aqua-400: #2bc8d7;
$aqua-600: #072022;
$aqua-500: #177078;
$aqua-200: #ccf2f5;
$aqua-300: #88e0e7;
$aqua-100: #eefbfc;
$purple-400: #604bba;
$purple-600: #0f0b1e;
$purple-300: #a397d8;
$purple-200: #d8d3ee;
$purple-500: #332768;
$purple-100: #f6f3ff;
$green-500: #008f4a;
$green-200: #c2ffe1;
$green-100: #ebfff5;
$green-600: #002915;
$green-400: #00e676;
$green-300: #85ffc4;
$grey-600: #111418;
$grey-550: #1d2936;
$grey-400: #95a5b6;
$grey-300: #cfd5de;
$grey-100: #f3f5f7;
$grey-200: #e7eaee;
$grey-500: #435160;
$red-600: #29000a;
$red-500: #a30026;
$red-200: #ffc2d1;
$red-300: #ff7e9d;
$red-400: #ff0a43;
$red-100: #ffebf0;
$orange-600: #291200;
$orange-400: #ff6d00;
$orange-500: #cc5800;
$orange-300: #ff9747;
$orange-200: #ffdac2;
$orange-100: #fff3eb;
$yellow-600: #291e00;
$yellow-500: #b88700;
$yellow-400: #ffc932;
$yellow-300: #ffd54f;
$yellow-200: #ffefc2;
$yellow-100: #fffaeb;
$colors-filled-solid-black: #212121;
$white: #ffffff;
$blue-aaa: #1f2936;
$gold: #b6905e;

$red: #ff0a43;
$orange: #ff6d00;
$yellow: #ffc932;
$purple: #604bba;
$green: #00e676;
$gray: #95a5b6;
$aqua: #2bc8d7;
$sky: #0ac2ff;
$blue: #2979ff;
$primary: $blue;

$text-muted: #95a5b6;

$border-color: #cfd5de;
$border-active-color: #2979ff;
$border-width: 1px;

$border-radius: 10px;
$border-radius-sm: 5px;

$spacers: (
	0: 0px,
	1: 4px,
	2: 8px,
	3: 12px,
	4: 16px,
	5: 24px,
	6: 32px,
	7: 48px,
	8: 56px,
	9: 64px,
	10: 80px,
);
$spacer: 24px;
$grid-columns: 12;
$grid-gutter-width: 48px;
$grid-row-columns: 6;

$font-family: "Open Sans";

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
);

$text-color: #212121;



$typeface-h0: 96px;
$typeface-h1: 60px;
$typeface-h2: 48px;
$typeface-h3: 36px;
$typeface-h4: 24px;
$typeface-h5: 20px;
$typeface-h6: 16px;
$typeface-small: 10px;
$typeface-caption: 10px;

$weight-regular: 400;
$weight-bold: 500;
$weight-bolder: 800;

$themes: (
	"primary": $primary,
	"red": $red,
	"orange": $orange,
	"yellow": $yellow,
	"purlple": $purple,
	"green": $green,
	"gray": $gray,
	"aqua": $aqua,
	"sky": $sky,
	"blue": $blue,
);

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

@mixin make-container($gutter: $grid-gutter-width) {
	width: 100%;
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
	margin-right: auto;
	margin-left: auto;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

@mixin make-row($gutter: $grid-gutter-width) {
	display: flex;
	flex-wrap: wrap;
	margin-right: -$gutter / 2;
	margin-left: -$gutter / 2;
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
	position: relative;
	width: 100%;
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 percentage($size / $columns);
	max-width: percentage($size / $columns);
}

@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: $size / $columns;
	margin-left: if($num == 0, 0, percentage($num));
}
@mixin row-cols($count) {
	& > * {
		flex: 0 0 100% / $count;
		max-width: 100% / $count;
	}
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	%grid-column {
		position: relative;
		width: 100%;
		padding-right: $gutter / 2;
		padding-left: $gutter / 2;
	}

	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@for $i from 1 through $columns {
			.v4-col#{$infix}-#{$i} {
				@extend %grid-column;
			}
		}
		.v4-col#{$infix},
		.v4-col#{$infix}-auto {
			@extend %grid-column;
		}

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			.v4-col#{$infix} {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;
			}

			@for $i from 1 through $grid-row-columns {
				.v4-row-cols#{$infix}-#{$i} {
					@include row-cols($i);
				}
			}

			.v4-col#{$infix}-auto {
				@include make-col-auto();
			}

			@for $i from 1 through $columns {
				.v4-col#{$infix}-#{$i} {
					@include make-col($i, $columns);
				}
			}

			.v4-order#{$infix}-first {
				order: -1;
			}

			.v4-order#{$infix}-last {
				order: $columns + 1;
			}

			@for $i from 0 through $columns {
				.v4-order#{$infix}-#{$i} {
					order: $i;
				}
			}

			@for $i from 0 through ($columns - 1) {
				@if not($infix == "" and $i == 0) {
					.v4-offset#{$infix}-#{$i} {
						@include make-col-offset($i, $columns);
					}
				}
			}
		}
	}
}

@function color-variation($color) {
	$b: lightness($color);
	@return (
		600: darken($color, 44 / $b * 50),
		500: darken($color, 20 / $b * 50),
		400: lighten($color, 0),
		300: lighten($color, 23 / $b * 50),
		200: lighten($color, 36 / $b * 50),
		100: lighten($color, 40 / $b * 50),
	);
}

@function color-yiq($color, $dark: $text-color, $light: $white) {
	$r: red($color);
	$g: green($color);
	$b: blue($color);
	$yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;
	@if ($yiq >= 150) {
		@return $dark;
	} @else {
		@return $light;
	}
}
