@import '../site/_variables-v2';
@import '../site/_common-v2';
@import '../site/variables-v3';
@import '../site/common-v3';
@import '../site/utilities/icons-v3';
@import '../../../../public/plugins/style-guide-v4/variables.scss';

* {
	font-family: "Open Sans", "Avenir Next", "Helvetica", Arial, sans-serif;
}

.v4-container {
	@include media-breakpoint-down("sm") {
		padding: 0 12px;
	}
}

.rv-listing {
	&-section {
		margin: 44px 0;

		@include media-breakpoint-down("sm") {
			margin: 24px 0;
		}

		&-headline {
			font-size: 32px;
			font-weight: bold;
			color: #111418;

			@include media-breakpoint-down("sm") {
				font-size: 20px
			}
		}
	}

	&-header {
		padding: 24px 0;

		@include media-breakpoint-down("sm") {
			padding: 0;
		}

		&-divider {
			border-bottom: solid 1px #cfd5de;
		}

		&-top {
			display: flex;
			justify-content: space-between;

			@include media-breakpoint-down("sm") {
				flex-direction: column;
			}
		}

		&-left {
			display: flex;
			flex-direction: column;

			@include media-breakpoint-down("sm") {
				padding-bottom: 12px;
				border-bottom: solid 1px #cfd5de;
			}
		}

		&-right {
			@include media-breakpoint-down("sm") {
				padding: 24px 0;
				border-bottom: solid 1px #cfd5de;
			}
		}

		&-address {
			font-size: 24px;
			font-weight: bold;
			color: #111418;
			align-items: center;
			display: flex;

			@include media-breakpoint-down("sm") {
				font-size: 16px;
			}

			img {
				height: 28px;

				@include media-breakpoint-down("sm") {
					height: 20px;
				}
			}
		}

		&-id-share {
			display: none;
			height: auto;
			padding-right: 0;

			@include media-breakpoint-down("sm") {
				display: block;
			}
		}

		&-id-holder { 
			display: flex;
			justify-content: space-between;
			margin-top: 12px;
		}

		&-id {
			font-size: 16px;

			@include media-breakpoint-down("sm") {
				font-size: 12px;
			}

			span {
				font-weight: bold;
			}
		}

		&-items {
			display: flex;
		}

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0 24px;
			border-right: solid 1px #cfd5de;

			@include media-breakpoint-down("sm") {
				border-right: 0;
				padding: 0 12px;
			}

			&:last-child {
				border-right: 0;
				align-items: flex-end;
				padding-right: 0;
			}

			&:first-child {
				@include media-breakpoint-down("sm") {
					padding-left: 0;
					align-items: flex-start;
				}
			}

			&:nth-child(2) {
				@include media-breakpoint-down("sm") {
					margin-left: auto;
				}
			}

			&-title {
				font-size: 24px;
				font-weight: bold;

				@include media-breakpoint-down("sm") {
					font-size: 16px;
				}
			}

			&-desc {
				font-size: 14px;
				display: flex;
				align-items: center;

				@include media-breakpoint-down("sm") {
					font-size: 12px;
				}
			}
		}

		&-bottom {
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media-breakpoint-down("sm") {
				margin-top: 8px;
				margin-bottom: 8px;
			}

			&-share {
				color: #fff;

				@include media-breakpoint-down("sm") {
					display: none;
				}
			}
			
			&-items {
				display: flex;
				align-items: center;
				align-self: flex-end;

				@include media-breakpoint-down("sm") {
					width: 100%;
					justify-content: space-between;
				}
			}

			&-item {
				display: flex;	
				margin-right: 44px;
				font-size: 16px;

				@include media-breakpoint-down("sm") {
					font-size: 12px;
				}

				&:last-child {
					margin-right: 0;
				}

				span {
					font-weight: bold;
				}
			}
		}
	}

	&-body {
		padding-top: 24px;
		display: flex;

		@include media-breakpoint-down("sm") {
			padding-top: 0px;
		}
	}

	&-main {
		width: 100%;

		@include media-breakpoint-down("sm") {
			width: 100%;	
			margin-bottom: 70px;
		}

		&.with-branding {
			width: 60%;

			@include media-breakpoint-down("sm") {
				width: 100%;	
			}
		}
	}

	&-side {
		width: 40%;
		padding-left: 24px;

		@include media-breakpoint-down("sm") {
			display: none;

			&.show {
				width: 100%;
				background: #fff;
				position: fixed;
				left: 0;
				top: 0;
				height: 100%;
				padding: 0;
				display: block;
				z-index: 100;
				overflow: scroll;
			}
		}

		&-close {
			display: none;
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 10;

			@include media-breakpoint-down("sm") {
				display: block;
			}
		}
	}

	&-agent {
		padding: 24px;
		border-radius: 10px;
		box-shadow: 0 5px 20px 10px rgba(0, 0, 0, 0.1);

		@include media-breakpoint-down("sm") {
			min-height: 100%;
		}

		&-profile {
			display: flex;
			align-items: center;
			
			&-image {
				width: 55px;
				height: 55px;
				margin-right: 12px;
				box-shadow: 0 5px 10px 5px rgba(31, 94, 199, 0.08);
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 100%;
					// border: solid 5px #fff;
				}

				&-initials {
					background: linear-gradient(to bottom,#ffd100,#ffa400);
					display: flex;
					justify-content: center;
					align-items: center;					
					height: 100%;
					width: 100%;
				}
			}

			&-info {
				display: flex;
				flex-direction: column;
			}

			&-name {
				font-size: 16px;
				font-weight: bold;
			}

			&-phone {
				font-size: 14px;
			}
		}

		&-success {
			display: flex;
			justify-content: center;

			h4 {
				color: #098226;
				font-weight: bold;
				font-size: 18px;
			}
		}

		&-headline {
			font-size: 14px;
			margin: 24px 0;
		}

		.g-recaptcha-container {
			margin-top: 12px;
		}
	}

	&-media {
		@include media-breakpoint-down("sm") {
			padding: 12px;
		}

		&-content {
			margin-bottom: 24px;
			border-radius: 10px;
			overflow: hidden;
			position: relative;

			@include media-breakpoint-down("sm") {
				margin-bottom: 8px;
			}

			&-item {
				&.rv-listing-map {
					height: 450px;
					z-index: 0;

					@include media-breakpoint-down("sm") {
						height: 200px;
					}		
				}
			}
		}

		&-items {
			display: flex
			
		}

		&-item {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 7px 24px;
			align-items: center;
			background-color: #f3f5f7;
			border: solid 2px #cfd5de;
			margin-right: 24px;
			border-radius: 10px;
			font-size: 16px;
			font-weight: 600;
			cursor: pointer;
			text-align: center;

			@include media-breakpoint-down("sm") {
				margin-right: 8px;
				padding: 2px 8px;
			}

			&:last-child {
				margin-right: 0;
			}

			&.active {
				border-color: #2979ff;
				color: #2979ff;

				.rv-listing-media-item-icon.active {
					display: block;
				}
				.rv-listing-media-item-icon.deactive {
					display: none;
				}
			}

			&-icon {
				margin-bottom: 12px;

				@include media-breakpoint-down("sm") {
					margin-bottom: 8px;
				}

				&.active {
					display: none;
				}
			}
		}

	}

	&-slider {
		border-radius: 10px;
		overflow: hidden;
		cursor: pointer;

		.swiper-slide {
			img {
				border-radius: 10px;
				overflow: hidden;
				width: 100%;
			}
		}

		&-nav {
			position: absolute;
			top: 0;
			width: 10%;
			height: 100%;
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;

			&.swiper-button-disabled {
				display: none;
			}

			&.prev {
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
				border-radius: 10px 0 0 10px;
				left: 0;
			}
			
			&.next {
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
				border-radius: 0 10px 10px 0;
				right: 0;
			}

			i {
				font-size: 35px;
			}
		}
	}

	&-big-slider {
		position: fixed;
		z-index: 2001;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: block;
		align-items: center;
		justify-content: center;
		overflow-y: scroll;

		&-inner {
			width: 60%;
			margin: 44px auto 5px auto;
			overflow: hidden;
			border-radius: 10px;
		}

		&-close {
			position: fixed;
			top: 5%;
			right: 5%;
			color: #fff;
			cursor: pointer;
	
			i {
				font-size: 40px;
			}
		}

		.swiper-slide {
			img {
				border-radius: 10px;
				overflow: hidden;
				width: 100%;
			}
		}

		&-nav {
			position: absolute;
			top: 0;
			width: 10%;
			height: 100%;
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;

			&.swiper-button-disabled {
				display: none;
			}

			&.prev {
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
				border-radius: 10px 0 0 10px;
				left: 0;
			}
			
			&.next {
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
				border-radius: 0 10px 10px 0;
				right: 0;
			}

			i {
				font-size: 35px;
			}
		}
	}

	&-description {
		font-size: 16px;

		&-text {
			color: #111418;

			&-hide {
				overflow: hidden;
				text-overflow: ellipsis;
				content: "";
				position: relative;
				max-height: 100px;
			}
		}

		&-more {
			display: inline-flex;
			align-items: center;
			color: #2979ff;
			cursor: pointer;
			margin-top: 12px;
		}

		&-by {
			font-size: 16px;
			margin-top: 24px;

			span {
				font-weight: bold;
				margin-right: 12px;
			}
		}
	}

	&-general {
		&-title {
			font-size: 20px;
				
			@include media-breakpoint-down("sm") {
				font-size: 16px;
			}
		}

		&-section {
			display: flex;
			text-align: left;
		}
		
		&-transit-walk-holder {
			margin-top: 44px;

			@include media-breakpoint-down("sm") {
				margin-top: 24px;
			}
		}

		&-left {
			flex: 1;
		}

		&-right {
			flex: 1;
		}

		&-box {
			margin-top: 24px;
			margin-bottom: 44px;
			display: flex;
			align-items: center;

			&-num {
				color: #2979ff;
				margin-right: 12px;
				padding: 12px;
				font-size: 36px;
				border-radius: 10px;
				box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.07);
				background-color: #fff;
				line-height: 1;

				@include media-breakpoint-down("sm") {
					font-size: 29px;
				}
			}

			&-desc {
				font-size: 16px;

				@include media-breakpoint-down("sm") {
					font-size: 14px;
				}
			}
		}

		&-subtitle {
			font-size: 16px;

			@include media-breakpoint-down("sm") {
				font-size: 14px;
			}

			&-value {
				font-size: 24px;
				font-weight: bold;
				
				@include media-breakpoint-down("sm") {
					font-size: 20px;
				}
			}
		}
	}

	&-detailed {
		&-items {
			margin-top: 44px;
			display: flex;
			justify-content: space-between;

			@include media-breakpoint-down("sm") {
				margin-top: 24px;
			}
		}

		&-item {
			&-title {
				font-size: 16px;
				font-weight: normal;
				
				@include media-breakpoint-down("sm") {
					font-size: 14px;
				}
			}

			&-value {
				font-size: 24px;
				font-weight: bold;
				
				@include media-breakpoint-down("sm") {
					font-size: 20px;
				}
			}
		}

		&-title {
			margin-top: 24px;
			font-size: 16px;
		}

		&-value {
			font-size: 16px;
			font-weight: bold;
		}
	}

	&-mortgage {
		.rv-listing-section-headline {
			justify-content: space-between;
			display: flex;
			align-items: center;

			a {
				font-size: 14px;
				font-weight: 600;
				color: #2979ff;
			}
		}

		&-items {
			display: flex;
			justify-content: space-between;	

			@include media-breakpoint-down("sm") {
				flex-wrap: wrap;
				justify-content: center;
			}
		}

		&-item {
			margin-right: 12px;
			padding: 24px;
			border-radius: 10px;
			box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.07);
			text-align: center;

			@include media-breakpoint-down("sm") {
				margin-bottom: 12px;
			}

			&:last-child {
				margin-right: 0;

				@include media-breakpoint-down("sm") {
					margin-right: 12px;
				}
			}

			&-title {
				font-size: 16px;
				
				@include media-breakpoint-down("sm") {
					font-size: 14px;
				}
			}

			&-price {
				font-size: 32px;
				font-weight: bold;
				
				@include media-breakpoint-down("sm") {
					font-size: 24px;
				}
			}

			&-subtitle {
				font-size: 14px;
				
				@include media-breakpoint-down("sm") {
					font-size: 12px;
				}
			}
		}
		
		&-note {
			color: #95a5b6;
			font-size: 12px;
		}
	}

	&-school {
		&-table {
			border-collapse: separate;
    		border-spacing: 0 24px;

			th {
				padding: 0 24px;
			}

			td {
				padding: 24px;

				&:first-child {
					font-weight: bold;
				}
			}
			
			tbody tr {
				box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.07);
				border-radius: 10px;
			}
		}
	}

	&-nearby {
		&-title {
			font-size: 20px;
			margin-top: 24px;
			
			@include media-breakpoint-down("sm") {
				font-size: 18px;
			}
		}

		&-items {
			display: flex;
			flex-wrap: wrap;
			margin-top: 24px;
		}

		&-item {
			padding: 12px;
			border-radius: 10px;
			box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.07);
			background-color: #fff;
			font-size: 16px;
			font-weight: bold;
			margin-right: 24px;

			@include media-breakpoint-down("sm") {
				padding: 20px;
				font-size: 14px;
				margin-right: 12px;
			}
		}
	}

	&-quick {
		padding-top: 24px;
		padding-bottom: 12px;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		background-color: #fff;
		z-index: 100;

		@include media-breakpoint-down("sm") {
			display: none;
		}

		&-title {
			color: #95a5b6;
			font-size: 16px;
		}

		&-items {
			display: flex;
		}

		&-item {
			margin-right: 44px;
			color: #435160;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			&.active {
				color: #111418;
				font-weight: 600;
			}
		}
	}

	&-mobile-branding {
		display: none;
		background: #fff;
		padding: 10px;
		box-shadow: 0 5px 20px 10px rgba(0, 0, 0, 0.1);
		position: fixed;
		bottom: 0;
		width: 100%;

		@include media-breakpoint-down("sm") {
			display: block;
		}
	}

	.tour-frame {
		width: 100% !important;
	}
}

// Fixes for old design
.share-overlay-content .agree-terms {
	margin-top: 23px !important;
}
.flex-spacing {
    flex-grow: 1;
}

@include media-breakpoint-down("sm") {
	.new-personal-nav {
		display: none;
	}
}